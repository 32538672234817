import Layout from 'components/Layout';
import React from 'react';
import seo from 'config/seo.json';

export default class Articles extends React.Component {
	render() {
		return (
			<Layout
				className="bg-light-gray"
				title={seo.blog.title}
				description={seo.blog.description}
			>
				<section className="max-w-7xl mx-auto py-12 px-4">
					<iframe
						className="mt-4"
						frameBorder="0"
						height="650"
						src={`https://homekeepr.com/blog`}
						style={{
							border: 'none',
							width: '100%',
							height: '85vh',
						}}
						width="100%"
					></iframe>
				</section>
			</Layout>
		);
	}
}
